// import React from 'react';
// import IndexView from 'demos/IndexView';

// const IndexPage = () => {
//   return <IndexView />;
// };

// export default IndexPage;
import React from 'react';
import ServerError from 'views/Error';

const ErrorPage = () => {
  return <ServerError />;
};

export default ErrorPage;
